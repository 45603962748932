import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import user from '@/shared/model/user';

const name = 'my-data-edit-dialog';
const logger = new Logger(name);

@Component({ name: name })
export default class DataEditDialog extends Vue {
  @Ref('my-data-edit-form')
  private refEditFolderForm!: any;
  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: false })
  public isLoading!: boolean;
  private isFormValid = true;
  @Prop({ default: () => user.parse({}) })
  private value!: { email: string; firstName: string; lastName: string; kurzform: string; password: string };
  @Watch('dialog')
  onUpdateDialog(newV: any, oldV: any) {
    if (newV) this.resetValidation();
  }
  @Watch('value')
  onUpdateValue(newV: any, oldV: any) {
    this.model = Object.assign(this.model, newV);
    this.retype = '';
  }
  private model: {
    email: string;
    firstName: string;
    lastName: string;
    kurzform: string;
    password: string;
    pdfZoom: string;
    phoneNumber: string;
  } = {
    email: '',
    firstName: '',
    lastName: '',
    kurzform: '',
    password: '',
    pdfZoom: '',
    phoneNumber: '',
  };
  retype = '';
  show = {
    password: false,
    retype: false,
  };
  get rules() {
    return {
      required: (value: any) => !!value || this.$t('required'),
      min: (v: any) => v.length >= 8 || this.$t('min'),
      requireUppercase: (v: any) => (v && /[A-Z]{1}/.test(v)) || this.$t('require_uppercase'),
      requireLowercase: (v: any) => (v && /[a-z]{1}/.test(v)) || this.$t('require_lowercase'),
      requireDigit: (v: any) => (v && /\d/.test(v)) || this.$t('require_digit'),
      emailMatch: (retype: any) => retype != this.model.email || this.$t(`email_match`),
      passMatch: (x: any) => x == this.model.password || this.$t(`pass_match`),
    };
  }

  resetValidation() {
    if (this.refEditFolderForm) this.refEditFolderForm.resetValidation();
  }
  async onClickedUpdate() {
    const isFormValidResult = await this.refEditFolderForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.model);
    }
  }

  onClickedClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  get pdfSettings() {
    return [
      { name: this.$t('automatic'), value: '1' },
      { name: this.$t('original_size'), value: '2' },
      { name: this.$t('page_size'), value: '3' },
      { name: this.$t('page_width'), value: '4' },
    ];
  }
}
